import React, { useEffect } from "react";
import { LayerDefinition } from "./LayerDefinition";
import { useMapContext } from "./AppMap";
import { MVT } from "ol/format";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import Circle from "ol/style/Circle";
import { VectorTileLoadFunction } from "./utils/VectorTileLoadFunction";
import TileGrid from "ol/tilegrid/TileGrid";
import { get as getProjection } from 'ol/proj';
import { Feature, Map } from "ol";
import OlMap from 'ol/Map';
import { StyleBuilder } from "./styling/StyleBuilder";
import { LayerStyling } from "./styling/LayerStyling";
import { EventsKey } from "ol/events";
import { unByKey } from "ol/Observable";
import { GeometryType } from "@opt/core";

type MVTLayerProps = {
  definition: LayerDefinition,
  map?: OlMap,
  maxZoom?: number,
  tileCacheSize?: number
}

const defaultFill = new Fill({
  color: 'rgba(255, 0, 255, 0)'
});

const defaultStroke = new Stroke({
  color: 'rgba(204, 0, 204, 1)',
  width: 2
});

const defaultStyle: Style = new Style({
  image: new Circle({
    fill: defaultFill,
    stroke: defaultStroke,
    radius: 5,
  }),
  fill: defaultFill,
  stroke: defaultStroke,
});

const MVTLayer: React.FC<MVTLayerProps> = ({ definition, map, maxZoom = 12, tileCacheSize = 1000 }) => {

  const mapContext = useMapContext();
  const resolutions: number[] = [];

  let _handlerLayer: EventsKey | undefined;
  let labelLayer: VectorTileLayer | undefined;

  for (let i = 0; i <= maxZoom; ++i) {
    resolutions.push(156543.03392804097 / Math.pow(2, i));
  }

  useEffect(() => {

    const webmap = mapContext?.map ?? map;

    if (!definition || !webmap) return;

    const layer = new VectorTileLayer({
      declutter: definition.geometryType !== GeometryType.Point,
      visible: definition.visible,
      extent: definition.extent,
      renderMode: 'hybrid',
      minZoom: definition?.styleDefinition?.minVisibleZoom,
      maxZoom: definition.styleDefinition?.maxVisibleZoom,
      source: new VectorTileSource({
        format: new MVT({ featureClass: Feature }),
        url: definition.url,
        cacheSize: tileCacheSize,
        tileLoadFunction: VectorTileLoadFunction(),
        tileGrid: new TileGrid({
          extent: getProjection('EPSG:3857')?.getExtent(),
          resolutions: resolutions
        }),
      }),
      style: definition.style ?? defaultStyle
    });

    if (definition.styleDefinition) {
      layer.setStyle(StyleBuilder.fromLayerStyling(definition.styleDefinition))
    }

    layer.set("LAYER_DEFINITION", definition);
    webmap.addLayer(layer);
    layer.setZIndex(definition.zIndex);


    definition.addEventListener("propertychange", (e: any) => {
      if (e.detail.key === "styleDefinition") {
        labelLayer = createLabelLayer(webmap, layer);
      }
    })

    if (definition.styleDefinition?.textTableID) {
      labelLayer = createLabelLayer(webmap, layer);
    }

    return () => {
      webmap.removeLayer(layer);
      if (labelLayer) webmap.removeLayer(labelLayer);
    }

  }, [definition, mapContext, map]);

  const createLabelLayer = (webmap: Map, sourceLayer: VectorTileLayer) => {
    if (labelLayer) webmap.removeLayer(labelLayer);
    if (_handlerLayer) unByKey(_handlerLayer);

    if (!definition.styleDefinition?.textTableID) return undefined;

    const labelUrl = definition.url.replace(definition.key, definition.styleDefinition?.textTableID as string);

    const layer = new VectorTileLayer({
      declutter: true,
      visible: definition.visible,
      extent: definition.extent,
      renderMode: 'hybrid',
      minZoom: definition?.styleDefinition?.minVisibleZoom,
      maxZoom: definition.styleDefinition?.maxVisibleZoom,
      source: new VectorTileSource({
        format: new MVT({ featureClass: Feature }),
        url: labelUrl,
        cacheSize: tileCacheSize,
        tileLoadFunction: VectorTileLoadFunction(),
        tileGrid: new TileGrid({
          extent: getProjection('EPSG:3857')?.getExtent(),
          resolutions: resolutions
        }),
      }),
      style: StyleBuilder.textStyleFromLayerStyling(definition.styleDefinition as LayerStyling)
    });

    webmap.addLayer(layer);
    layer.setZIndex(definition.zIndex);

    _handlerLayer = sourceLayer.on("propertychange", (e) => {
      layer.set(e.key, sourceLayer.get(e.key));
    })

    return layer;
  }

  return (<></>)
}

export default MVTLayer;
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, Button, Card, CardContent, FormControl, FormControlLabel, InputLabel, ListItemButton,
  ListItemIcon, ListItemText, MenuItem, Popover, Radio, RadioGroup, Select, Stack, Tooltip, Typography
} from "@mui/material";
import DateRange from "@opt/ui-core/src/components/DateRange";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { startOfDay, endOfDay, subDays } from 'date-fns';
import { FixedSizeList, ListChildComponentProps } from "react-window";
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import { useExplorerStore } from "./ExplorerStore";
import Map from "ol/Map";
import { Extent } from "ol/extent";
import { FormatDate, FormatDecimalNumber, useUserAuthStore } from "@opt/core";
import { MonitoraStacSearchProvider } from "./MonitoraStacSearchProvider";
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { StacItem } from "../../models/StacItem";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import ClearIcon from '@mui/icons-material/Clear';
import { ISTACSearchProvider } from "./ISTACSearchProvider";
import { OnlineRasterViews, PlanetaryComputerStacSearchProvider } from "./PlanetaryComputerStacSearchProvider";
import { useNotifyStore } from "@opt/ui-core";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { SensorRasterView } from "../../models/SensorRasterView";
import LayersIcon from '@mui/icons-material/Layers';

type STACSearcherProps = {
  map?: Map,
  width?: number,
  defaultExpanded?: boolean
}

const STACSearcher: React.FC<STACSearcherProps> = ({ map, defaultExpanded, width }) => {

  const ranges: { label: string, value: [Date, Date] }[] = [
    {
      label: `${t('pages.inspections.today')}`,
      value: [startOfDay(new Date()), endOfDay(new Date())]
    },
    {
      label: `${t('pages.inspections.lastDays')} 5 ${t('pages.inspections.days')}`,
      value: [startOfDay(subDays(new Date(), 5)), endOfDay(new Date())]
    },
    {
      label: `${t('pages.inspections.lastDays')} 15 ${t('pages.inspections.days')}`,
      value: [startOfDay(subDays(new Date(), 15)), endOfDay(new Date())]
    },
    {
      label: `${t('pages.inspections.lastDays')} 30 ${t('pages.inspections.days')}`,
      value: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())]
    },
    {
      label: `${t('pages.inspections.lastDays')} 60 ${t('pages.inspections.days')}`,
      value: [startOfDay(subDays(new Date(), 60)), endOfDay(new Date())]
    }
  ];

  const { filterCollection, filterRangeDate, collections, stacItems,
    setCollections, setResults,
    setFilterCollection, setFilterRangeDate } = useExplorerStore();

  const { addMsg } = useNotifyStore();
  const [currentLayer, setCurrentLayer] = useState<TileLayer<XYZ>>();
  const [currentItem, setCurrentItem] = useState<StacItem>();
  const [anchor, setAnchor] = React.useState<[number, number] | undefined>();
  const open = Boolean(anchor);
  const [providerType, setProviderType] = React.useState('local');
  const [provider, setProvider] = useState<ISTACSearchProvider>(new MonitoraStacSearchProvider());
  const { currentTenant } = useUserAuthStore();

  useEffect(() => {
    const availableViews = currentTenant?.features?.onlineProviderViews ? JSON.parse(currentTenant?.features?.onlineProviderViews) : {};
    const newProvider = providerType === 'local' ? new MonitoraStacSearchProvider() : new PlanetaryComputerStacSearchProvider(availableViews as OnlineRasterViews);

    setProvider(newProvider);

    newProvider
      .getCollections()
      .then(cols => setCollections(cols))
      .catch(() => {
        addMsg("error", t('components.explorer.stacSearcher.providerUnavailable'));
      });

    setResults([]);
    handleRemoveLayer();
  }, [providerType]);

  useEffect(() => {
    if (collections.length > 0) {
      setFilterCollection(collections[0].id as string);
    }
  }, [collections])

  const handleSearchCliked = () => {
    const bbox = map?.getView().calculateExtent() as Extent;

    provider
      .queryScenes(filterCollection as string, bbox, filterRangeDate[0], filterRangeDate[1])
      .then(results => setResults(results))
      .catch(() => {
        addMsg("error", t('components.explorer.stacSearcher.providerUnavailable'));
      });
  }

  const handleClose = () => {
    setAnchor(undefined);
  }

  const handleAddLayer = (view: SensorRasterView) => {
    if (currentLayer) {
      map?.removeLayer(currentLayer);
    }

    const layer = provider.createItemLayer(currentItem as StacItem, view);
    setCurrentLayer(layer);

    map?.addLayer(layer);

    handleClose();
  }

  const handleRemoveLayer = () => {
    if (currentLayer) {
      map?.removeLayer(currentLayer);
    }

    setCurrentLayer(undefined);
  }

  const getRasterViews = () => {
    const selected = collections.find(x => x.id === filterCollection);
    return selected?.rasterViews;
  }

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;
    return (
      <>
        <ListItemButton
          style={style}
          key={`2-${index}`}
          onClick={(e) => {
            setCurrentItem(stacItems[index]);
            setAnchor([e.clientX, e.clientY]);
          }}
        >
          <ListItemIcon sx={{ minWidth: "30px" }}>
            <SatelliteAltIcon />
          </ListItemIcon>
          <Tooltip title={stacItems[index].id}>
            <Stack direction="column" sx={{ paddingLeft: "5px", width: "100%" }}>
              <ListItemText sx={{ minWidth: "130px", width: "130px" }}
                secondary={`Tile: ${stacItems[index].tile}`}
              />
              <Stack direction="row">
                <ListItemText sx={{ minWidth: "130px", width: "130px" }}
                  secondary={`Data: ${FormatDate(stacItems[index].date)}`}
                />
                <ListItemIcon sx={{ minWidth: "30px", fontSize: "14px" }}>
                  {`${FormatDecimalNumber(stacItems[index].cloudCover ?? 0)}%`}
                  <CloudQueueIcon sx={{ marginLeft: "5px" }} />
                </ListItemIcon>
              </Stack>
            </Stack>
          </Tooltip>
        </ListItemButton>
      </>
    );
  }

  return (
    <>
      <Card sx={{ maxWidth: width ?? 455, margin: "10px 20px" }}>
        <CardContent sx={{ paddingBottom: "10px!important", paddingTop: "10px!important" }}>
          <Typography gutterBottom variant="h6" component="div" color="primary">
            {t('components.explorer.stacSearcher.title')}
          </Typography>
          <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
            >
              <Typography>{t('components.explorer.stacSearcher.subtitle')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, margin: 0 }}>
              <Box>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="provider-group-label"
                    name="row-radio-buttons-group"
                    value={providerType}
                    onChange={(e) => setProviderType(e.target.value)}
                  >
                    <Tooltip title={t('components.explorer.stacSearcher.localProviderTip')}>
                      <FormControlLabel value="local" control={<Radio />} label={t('components.explorer.stacSearcher.localProvider')} />
                    </Tooltip>
                    <Tooltip title={t('components.explorer.stacSearcher.onlineProviderTip')}>
                      <FormControlLabel value="online" control={<Radio />} label={t('components.explorer.stacSearcher.onlineProvider')} />
                    </Tooltip>
                  </RadioGroup>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 220, maxWidth: 220 }} size="small">
                  <InputLabel id="filter-collection-label">{t('components.explorer.stacSearcher.collection')}</InputLabel>
                  <Select
                    labelId="filter-collection-label"
                    id="filter-collection"
                    label={t('pages.inspections.statusLabel')}
                    value={filterCollection ?? ''}
                    onChange={(e) => setFilterCollection(e.target.value)}
                  >
                    {collections?.map(x => {
                      return <MenuItem key={x.id} value={x.id}>{x.description}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 250, maxWidth: 250 }} size="small">
                  <DateRange
                    initialValue={[startOfDay(subDays(new Date(), 30)), endOfDay(new Date())]}
                    shortcuts={ranges}
                    label={t('components.explorer.stacSearcher.dateRange')}
                    value={filterRangeDate}
                    onChange={(e) => setFilterRangeDate(e)}
                  />
                </FormControl>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleSearchCliked()}
                  sx={{ marginBottom: "10px" }}
                  disabled={!filterCollection}>
                  {t('components.explorer.stacSearcher.button')}
                </Button>
                {stacItems.length === 0 &&
                  <Typography variant="body1" color="primary">{t('components.explorer.stacSearcher.noResults')}</Typography>}
                {!!currentLayer &&
                  <Box>
                    <Button
                      size="small"
                      variant="text"
                      color="error"
                      endIcon={<ClearIcon />}
                      onClick={handleRemoveLayer}
                    >{t('components.explorer.stacSearcher.removeLayer')}</Button>
                  </Box>}
                <FixedSizeList
                  height={300}
                  width={330}
                  itemSize={60}
                  itemCount={stacItems.length}
                  overscanCount={100}
                >
                  {renderRow}
                </FixedSizeList>
              </Box>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{ top: anchor?.[1] ?? 0, left: anchor?.[0] ?? 0 }}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ padding: "10px" }}>
          <Typography
            sx={{ textAlign: "center" }}
            color="primary"
            fontWeight={"bold"}
            variant="body1">{t('components.explorer.stacSearcher.itemOptions')}</Typography>
          <Typography
            color="primary"
            variant="body1">
            {t('components.explorer.stacSearcher.addLayer')}
          </Typography>
          <Stack alignItems="flex-start">
            {getRasterViews()?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)).map(x => {
              return <Button
                size="small"
                key={x.name}
                sx={{ width: "100%", justifyContent: "flex-start" }}
                variant="text"
                startIcon={<LayersIcon />}
                onClick={() => handleAddLayer(x)}>
                {x.description}
              </Button>
            })}
          </Stack>
        </Box>
      </Popover>
    </>
  )
}

export default STACSearcher;
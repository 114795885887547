import { Extent } from "ol/extent";
import { StacItem } from "../../models/StacItem";
import { ISTACSearchProvider } from "./ISTACSearchProvider";
import { transformExtent } from "ol/proj";
import { LayerDefinition, LayerMetadata, LayerType } from "@opt/mapping";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { fromExtent } from "ol/geom/Polygon";
import GeoJSON from 'ol/format/GeoJSON';
import { FormatDate } from "@opt/core";
import { Sensor } from "../../models/Sensor";
import { SensorRasterView } from "../../models/SensorRasterView";
import { t } from "i18next";

export type OnlineRasterViews = {
  ndvi?: boolean,
  ndmi?: boolean,
  nbr?: boolean,
  nmdi?: boolean
}

export class PlanetaryComputerStacSearchProvider implements ISTACSearchProvider {

  constructor(private availableViews: OnlineRasterViews) {

  }

  getCollections = async () => {
    const model = new Sensor();
    model.id = "sentinel-2-l2a";
    model.description = "Sentinel2-l2a";

    const rgb = new SensorRasterView();
    rgb.name = "RGB";
    rgb.description = t('components.explorer.onlineProvider.rasterViews.rgb');
    rgb.queryString = "assets=B04&assets=B03&assets=B02&unscale=false&resampling=nearest&color_formula=Gamma%20RGB%203.5%20Saturation%201.7%20Sigmoidal%20RGB%2015%200.35&return_mask=true";

    model.rasterViews.push(rgb);

    if (this.availableViews?.ndvi) {
      const ndvi = new SensorRasterView();
      ndvi.name = "NDVI";
      ndvi.description = t('components.explorer.onlineProvider.rasterViews.ndvi');
      ndvi.queryString = "expression=%28%28%28B08-1000%29%2F10000%29-%28%28B04-1000%29%2F10000%29%29%2F%28%28%28B08-1000%29%2F10000%29%2B%28%28B04-1000%29%2F10000%29%29&asset_as_band=True&colormap=%5B%5B%5B-1.0%2C-0.2%5D%2C%5B0%2C0%2C0%2C255%5D%5D%2C%5B%5B-0.2%2C0%5D%2C%5B165%2C0%2C38%2C255%5D%5D%2C%5B%5B0%2C0.1%5D%2C%5B215%2C48%2C39%2C255%5D%5D%2C%5B%5B0.1%2C0.2%5D%2C%5B244%2C109%2C67%2C255%5D%5D%2C%5B%5B0.2%2C0.3%5D%2C%5B253%2C174%2C97%2C255%5D%5D%2C%5B%5B0.3%2C0.4%5D%2C%5B254%2C224%2C139%2C255%5D%5D%2C%5B%5B0.4%2C0.5%5D%2C%5B255%2C255%2C191%2C255%5D%5D%2C%5B%5B0.5%2C0.6%5D%2C%5B217%2C239%2C139%2C255%5D%5D%2C%5B%5B0.6%2C0.7%5D%2C%5B166%2C217%2C106%2C255%5D%5D%2C%5B%5B0.7%2C0.8%5D%2C%5B102%2C189%2C99%2C255%5D%5D%2C%5B%5B0.8%2C0.9%5D%2C%5B26%2C152%2C80%2C255%5D%5D%2C%5B%5B0.9%2C1.0%5D%2C%5B0%2C104%2C55%2C255%5D%5D%5D";

      model.rasterViews.push(ndvi);
    }

    if (this.availableViews?.ndmi) {
      const ndmi = new SensorRasterView();
      ndmi.name = "NDMI";
      ndmi.description = t('components.explorer.onlineProvider.rasterViews.ndmi');
      ndmi.queryString = "expression=%28%28%28B08-1000%29%2F10000%29-%28%28B11-1000%29%2F10000%29%29%2F%28%28%28B08-1000%29%2F10000%29%2B%28%28B11-1000%29%2F10000%29%29&asset_as_band=True&colormap=%5B%5B%5B-10%2C0.15%5D%2C%5B255%2C%2099%2C%2099%2C255%5D%5D%2C%5B%5B0.15%2C0.25%5D%2C%5B255%2C%20253%2C%20162%2C255%5D%5D%2C%5B%5B0.25%2C10%5D%2C%5B46%2C%20184%2C%20114%2C255%5D%5D%5D";

      model.rasterViews.push(ndmi);
    }

    if (this.availableViews?.nbr) {
      const nbr = new SensorRasterView();
      nbr.name = "NBR";
      nbr.description = t('components.explorer.onlineProvider.rasterViews.nbr');
      nbr.queryString = "expression=%28%28%28B08-1000%29%2F10000%29-%28%28B12-1000%29%2F10000%29%29%2F%28%28%28B08-1000%29%2F10000%29%2B%28%28B12-1000%29%2F10000%29%29&asset_as_band=True&colormap=%5B%5B%5B-1.0%2C-0.6%5D%2C%5B255%2C0%2C0%2C255%5D%5D%2C%5B%5B-0.6%2C-0.4%5D%2C%5B255%2C170%2C85%2C255%5D%5D%2C%5B%5B-0.4%2C-0.2%5D%2C%5B255%2C227%2C114%2C255%5D%5D%2C%5B%5B-0.2%2C10%5D%2C%5B255%2C255%2C255%2C0%5D%5D%5D";

      model.rasterViews.push(nbr);
    }

    if (this.availableViews?.nmdi) {
      const teste = new SensorRasterView();
      teste.name = "NMDI";
      teste.description = t('components.explorer.onlineProvider.rasterViews.nmdi');
      teste.queryString = "expression=%28%28%28B8A-1000%29%2F10000%29-%28%28%28B11-1000%29%2F10000%29-%28%28B12-1000%29%2F10000%29%29%29%2F%28%28%28B8A-1000%29%2F10000%29%2B%28%28%28B11-1000%29%2F10000%29-%28%28B12-1000%29%2F10000%29%29%29&asset_as_band=True&colormap=%5B%5B%5B-1.0%2C0.0%5D%2C%5B255%2C255%2C255%2C0%5D%5D%2C%5B%5B0.0%2C0.4%5D%2C%5B0%2C0%2C255%2C255%5D%5D%2C%5B%5B0.4%2C0.6%5D%2C%5B153%2C204%2C255%2C255%5D%5D%2C%5B%5B0.6%2C0.7%5D%2C%5B255%2C153%2C51%2C255%5D%5D%2C%5B%5B0.7%2C10.0%5D%2C%5B255%2C0%2C0%2C255%5D%5D%5D";

      model.rasterViews.push(teste);
    }

    return [model];
  }

  queryScenes = async (collection: string, extent: Extent, startDate: Date, endDate: Date) => {
    const bbox = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
    const polygon = fromExtent(bbox);
    const jsonPolygon = JSON.parse(new GeoJSON().writeGeometry(polygon));

    const filter = {
      "filter-lang": "cql2-json",
      "filter": {
        "op": "and",
        "args": [
          { "op": "=", "args": [{ "property": "collection" }, collection] },
          {
            "op": "s_intersects", "args": [{ "property": "geometry" },
              jsonPolygon
            ]
          },
          {
            "op": "anyinteracts", "args": [{ "property": "datetime" },
            { "interval": [startDate.toISOString(), endDate.toISOString()] }]
          }]
      },
      "sortby": [{ "field": "datetime", "direction": "desc" }],
      "limit": 50
    }

    const result = await fetch('https://planetarycomputer.microsoft.com/api/stac/v1/search', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/geo+json'
      }),
      body: JSON.stringify(filter)
    })
      .then(async (response) => {
        if (!response.ok) throw new Error("Fail to post data.");
        const features = await response.json();

        const items = features?.features?.map((x: any) => {
          const item = new StacItem();
          item.collection = collection;
          item.id = x.id;
          item.date = x.properties.datetime;
          item.tile = x.properties["s2:mgrs_tile"];
          item.cloudCover = x.properties["eo:cloud_cover"]

          return item;
        });

        return items;
      });

    return result;
  }

  createItemLayer = (item: StacItem, view: SensorRasterView) => {
    const url = `https://planetarycomputer.microsoft.com/api/data/v1/item/tiles/WebMercatorQuad/{z}/{x}/{y}?collection=${item.collection}&item=${item.id}&${view.queryString}`;
    const definition = new LayerDefinition("raster-free-view", LayerType.Tiled, url, "", 0, true);

    const metadata = new LayerMetadata();
    metadata.provider = item.collection;
    metadata.id = item.id;
    metadata.date = FormatDate(item.date);

    definition.metadata = metadata;

    const layer = new TileLayer({
      visible: definition.visible,
      extent: definition.extent,
      minZoom: 8,
      maxZoom: 22,
      preload: Infinity,
      source: new XYZ({
        crossOrigin: "anonymous",
        cacheSize: 1000,
        url: definition.url
      })
    });

    layer.set("LAYER_DEFINITION", definition);

    return layer;
  }
}
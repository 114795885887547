import 'reflect-metadata';
import './App.css';
import './i18n';
import React, { Suspense } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLoginContext, useUserAuthStore } from '@opt/core'
import config from './config';
import { Alert, Box, Button, CircularProgress, CssBaseline, Divider, LinearProgress, MenuItem, Snackbar, styled, Typography } from "@mui/material";
import { Route, Routes } from 'react-router-dom';
import { Header, SideMenu } from '@opt/ui-core';
import { AiFillHome } from 'react-icons/ai';
import { BsFillGearFill } from 'react-icons/bs';
import CodeIcon from '@mui/icons-material/Code';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import PersonIcon from '@mui/icons-material/Person';
import ScienceIcon from '@mui/icons-material/Science';
import DatasetIcon from '@mui/icons-material/Dataset';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SatelliteIcon from '@mui/icons-material/Satellite';

import { ptBR as dataGridptBR } from '@mui/x-data-grid';
import { ptBR as coreptBR } from '@mui/material/locale';
import { ptBR } from '@mui/x-date-pickers/locales';

import HomePage from './pages/HomePage';
import AlertsPage from './pages/AlertsPage';
import AlertDetailsPage from './pages/AlertDetailsPage';
import ExecutionsPage from './pages/ExecutionsPage';
import { useTranslation } from 'react-i18next';
import * as locales from '@mui/material/locale';
import * as GridLocales from '@mui/x-data-grid';
import ExplorerListPage from './pages/ExplorerListPage';
import ExplorerMapPage from './pages/ExplorerMapPage';
import InspectionManualDetailsPage from './pages/InspectionManualDetailsPage';
import MetadataSearchPage from './pages/metadata/MetadataSearchPage';
import UserTenantAdminPage from './pages/admin/UserTenantAdminPage';

type SupportedLocales = keyof typeof locales;

const InspectionListPage = React.lazy(() => import('./pages/InspectionListPage'));
const InspectionDetailsPage = React.lazy(() => import('./pages/InspectionDetailsPage'));
const TableMetadataPage = React.lazy(() => import('./pages/metadata/TableMetadataPage'));
const FieldMetadataPage = React.lazy(() => import('./pages/metadata/FieldMetadataPage'));
const FormsMetadataPage = React.lazy(() => import('./pages/metadata/FormsMetadataPage'));
const SymbolMetadataPage = React.lazy(() => import('./pages/metadata/SymbolMetadataPage'));
const AlgorithmAdminPage = React.lazy(() => import('./pages/admin/AlgorithmAdminPage'));
const AlgorithmParamsAdminPage = React.lazy(() => import('./pages/admin/AlgorithmParamsAdminPage'));
const AlgorithmSensorAdminPage = React.lazy(() => import('./pages/admin/AlgorithmSensorAdminPage'));
const DatasetAdminPage = React.lazy(() => import('./pages/admin/DatasetAdminPage'));
const SensorAdminPage = React.lazy(() => import('./pages/admin/SensorAdminPage'));
const UserAdminPage = React.lazy(() => import('./pages/admin/UserAdminPage'));
const UserDatasetAdminPage = React.lazy(() => import('./pages/admin/UserDatasetAdminPage'));
const Sentinel2Scenes = React.lazy(() => import('./pages/admin/Sentinel2Scenes'));

const styles = {
  spinner: {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 100
  }
}

const StyledAuthDiv = styled('div')({
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  background: "radial-gradient(circle, rgba(183,218,86,1) 0%, rgba(105,123,132,1) 100%)",
  color: "white"
});

const Root = styled('div')({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  color: "#000133",
  margin: 0,
  padding: 0
});

const AppBar = styled('div')({
  background: "#00022E",
  color: "#FC86AA",
  height: "60px",
  margin: 0,
  padding: 0
});

const Container = styled('div')({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  alignItems: "stretch"
});

const Main = styled('div')({
  flex: 1,
  background: "#f7f5f5",
  color: "black",
  margin: 0,
  padding: 0
});

const Footer = styled('div')({
  background: "#00022E",
  height: "20px",
  color: "#B7DA56",
  margin: 0,
  padding: 0
});

const SideMenuWrapper = styled('div')({
  height: "calc(100vh - 80px)",
  width: "60px"
});

const StyledMenuIcon = (icon: any) => {
  return styled(icon)({
    '&': {
      marginLeft: "-15px"
    }
  });
};

function App() {

  const { authorized, getUserInfo, authenticated, roles, currentTenant } = useUserAuthStore();
  const [showAuthError, setShowAuthError] = useState(false);
  const { i18n } = useTranslation();
  const [locale, setLocale] = React.useState<SupportedLocales>('ptBR');
  const login = useLoginContext();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#697B84',
      },
      secondary: {
        light: '#B7DA56',
        main: '#9DD2AF',
        contrastText: '#71577F',
      },
    },
  },
    ptBR,
    dataGridptBR,
    coreptBR);

  const themeWithLocale = React.useMemo(
    () => createTheme(theme,
      (locales as any)[locale],
      (GridLocales as any)[locale]),
    [locale, theme],
  )

  useEffect(() => {
    if (!currentTenant) return;

    i18n.changeLanguage(currentTenant.language);
    const muiLocale = (currentTenant.language as string)?.replace('-', '') ?? 'ptBR';
    setLocale(muiLocale as any);

    if (currentTenant.languageOverrides) {
      const entries = JSON.parse(currentTenant.languageOverrides);
      Object.keys(entries).forEach(key => {
        i18n.addResource(currentTenant.language, "translations", key, entries[key]);
      })
    }
  }, [currentTenant])

  useEffect(() => {
    async function authorize() {
      try {
        await getUserInfo(config);
      }
      catch (ex) {
        console.log("error", ex)
        setShowAuthError(true);
      }
    }

    if (authenticated) {
      if (!authorized) {
        authorize();
      }
    }
  }, [authenticated]);

  const isAdmin = () => {
    return roles?.some(x => x === "tenant_admin");
  }

  const isAnalyst = () => {
    return roles?.some(x => x === "analyst");
  }

  const isInspector = () => {
    return roles?.some(x => x === "inspector");
  }

  const handleLogout = () => {
    console.log(login);
    login?.controller.doLogout();
  }

  return (
    <ThemeProvider theme={themeWithLocale}>
      {!authenticated &&
        <div>Loading</div>
      }

      {authenticated &&
        <>
          {!authorized &&
            <StyledAuthDiv>
              <Snackbar
                open={showAuthError}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  Usuário não autorizado!
                </Alert>
              </Snackbar>
              <Box sx={{ minWidth: "600px" }}>
                <Typography fontSize={26}>Autorizando acesso do usuário...</Typography>
                <Box sx={{ width: '100%', paddingTop: "30px" }}>
                  <LinearProgress />
                </Box>
              </Box>
            </StyledAuthDiv>
          }

          {authorized &&
            <Root>
              <CssBaseline />
              <AppBar>
                <Header logoArea={<>
                  <Box
                    component="img"
                    sx={{
                      height: 45,
                    }}
                    alt="OPT"
                    src={`/${config.base_href}/opt_logo.png`}
                  />
                  <Typography sx={{ marginLeft: "10px" }}>MONITORA</Typography>
                </>}
                  logoutArea={<Button size="small" onClick={() => handleLogout()}>SAIR</Button>}
                ></Header>
              </AppBar>
              <Container>
                <SideMenuWrapper>
                  <SideMenu items={[
                    { linkTo: "/", icon: StyledMenuIcon(AiFillHome), title: "Home" },
                    { linkTo: "/execution", icon: StyledMenuIcon(CrisisAlertIcon), title: "Lista de Execuções", hidden: !isAnalyst() },
                    { linkTo: "/inspections", icon: StyledMenuIcon(AssignmentTurnedInIcon), title: "Lista de Inspeções", hidden: !isInspector() },
                    { linkTo: "/explorer", icon: StyledMenuIcon(TravelExploreIcon), title: "Explorar Áreas de Interesse" },
                    {
                      linkTo: "/adm", icon: StyledMenuIcon(BsFillGearFill), title: "Configurações", hidden: !isAdmin(), children: [
                        { linkTo: "/metadata", icon: StyledMenuIcon(CodeIcon), title: "Metadados" },
                        { linkTo: "/users", icon: StyledMenuIcon(PersonIcon), title: "Usuários" },
                        { linkTo: "/algorithm", icon: StyledMenuIcon(ScienceIcon), title: "Algoritmos" },
                        { linkTo: "/dataset", icon: StyledMenuIcon(DatasetIcon), title: "Conjuntos" },
                        { linkTo: "/sensor", icon: StyledMenuIcon(SatelliteAltIcon), title: "Sensores" },
                        { linkTo: "/s2data", icon: StyledMenuIcon(SatelliteIcon), title: "Cenas S2" }
                      ]
                    }
                  ]}></SideMenu>
                </SideMenuWrapper>
                <Main>
                  <Routes>
                    <Route
                      path="/"
                      element={<HomePage />}
                    />
                    <Route
                      path="/explorer"
                      element={<ExplorerListPage />}
                    />
                    <Route
                      path="/explorer/:datasetID"
                      element={<ExplorerMapPage />}
                    />
                    {isAnalyst() &&
                      <>
                        < Route
                          path="/execution"
                          element={<ExecutionsPage />}
                        />
                        <Route
                          path="/execution/:executionID"
                          element={<AlertsPage />}
                        />
                        <Route
                          path="/alerts/:id"
                          element={<AlertDetailsPage />}
                        />
                      </>
                    }
                    {isInspector() && <>
                      <Route
                        path="/inspections"
                        element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                          <InspectionListPage />
                        </Suspense>}
                      />
                      <Route
                        path="/inspections/:aoi/:id?"
                        element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                          <InspectionDetailsPage />
                        </Suspense>}
                      />
                      <Route
                        path="/inspections/dataset/:dsid/:id?"
                        element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                          <InspectionManualDetailsPage />
                        </Suspense>}
                      />
                    </>
                    }
                    {isAdmin() &&
                      <>
                        <Route
                          path="/metadata"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <TableMetadataPage />
                          </Suspense>}
                        />
                        <Route
                          path="/metadata/:modelID/attributes"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <FieldMetadataPage />
                          </Suspense>}
                        />
                        <Route
                          path="/metadata/:modelID/forms"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <FormsMetadataPage />
                          </Suspense>}
                        />
                        <Route
                          path="/metadata/:modelID/symbol"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <SymbolMetadataPage />
                          </Suspense>}
                        />
                        <Route
                          path="/metadata/:modelID/search"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <MetadataSearchPage />
                          </Suspense>}
                        />
                        <Route
                          path="/users"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <UserAdminPage />
                          </Suspense>}
                        />
                        <Route
                          path="/users/:userID/datasets"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <UserDatasetAdminPage />
                          </Suspense>}
                        />
                        <Route
                          path="/users/:userID/tenants"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <UserTenantAdminPage />
                          </Suspense>}
                        />
                        <Route
                          path="/algorithm"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <AlgorithmAdminPage />
                          </Suspense>}
                        />
                        <Route
                          path="/algorithm/:algoName/params"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <AlgorithmParamsAdminPage />
                          </Suspense>}
                        />
                        <Route
                          path="/algorithm/:algoName/sensors"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <AlgorithmSensorAdminPage />
                          </Suspense>}
                        />
                        <Route
                          path="/dataset"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <DatasetAdminPage />
                          </Suspense>}
                        />
                        <Route
                          path="/sensor"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <SensorAdminPage />
                          </Suspense>}
                        />
                        <Route
                          path="/s2data"
                          element={<Suspense fallback={<CircularProgress size={68} sx={styles.spinner} />}>
                            <Sentinel2Scenes />
                          </Suspense>}
                        />
                      </>
                    }
                  </Routes>
                </Main>
              </Container>
              <Footer>
                <Typography fontSize={10} sx={{ paddingTop: "4px" }}>Copyright © 2024 - OPT Solutions - Todos os Direitos Reservados</Typography>
              </Footer>
            </Root>
          }
        </>
      }
    </ThemeProvider>
  );
}

export default App;
import { Extent } from "ol/extent";
import { GenericModel } from "../../models/GenericModel";
import { StacItem } from "../../models/StacItem";
import { ISTACSearchProvider } from "./ISTACSearchProvider";
import { DataFetchAPI, FormatDate } from "@opt/core";
import config from "../../config";
import { transformExtent } from "ol/proj";
import { LayerDefinition, LayerMetadata, LayerType, TileLoadFunction } from "@opt/mapping";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { Sensor } from "../../models/Sensor";
import { SensorRasterView } from "../../models/SensorRasterView";

const getCollections = () => {
  const { doFetchMany } = DataFetchAPI<Sensor>(Sensor, `${config.apiServices.url}/explorer/collections`);
  return doFetchMany;
}

const getStacItems = (collection: string, bbox: number[], startDate: string, endDate: string) => {
  const { doFetchMany } = DataFetchAPI<StacItem>(StacItem, `${config.apiServices.url}/explorer/collections/${collection}?sd=${startDate}&ed=${endDate}&bbox=${bbox}`);
  return doFetchMany;
}

export class MonitoraStacSearchProvider implements ISTACSearchProvider {

  constructor() {

  }

  getCollections = async () => {
    const query = await getCollections()() ?? [];
    return query;
  }

  queryScenes = async (collection: string, extent: Extent, startDate: Date, endDate: Date) => {
    const bbox = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
    const sd = FormatDate(startDate) as string;
    const ed = FormatDate(endDate) as string;

    const query = await getStacItems(collection, bbox, sd, ed)() ?? [];
    return query;
  }

  createItemLayer = (item: StacItem, view: SensorRasterView) => {
    const url = `${config.gisServices.url}/rasters/${item.collection}/${item.id}/{z}/{x}/{y}?${view.queryString}`;

    const definition = new LayerDefinition("raster-free-view", LayerType.Tiled, url, "", 0, true);

    const metadata = new LayerMetadata();
    metadata.provider = item.collection;
    metadata.id = item.id;
    metadata.date = FormatDate(item.date);

    definition.metadata = metadata;

    const layer = new TileLayer({
      visible: definition.visible,
      extent: definition.extent,
      minZoom: 8,
      maxZoom: 22,
      preload: Infinity,
      source: new XYZ({
        cacheSize: 1000,
        url: definition.url,
        tileLoadFunction: TileLoadFunction()
      })
    });

    layer.set("LAYER_DEFINITION", definition);

    return layer;
  }
}
import { Box, Button, Drawer, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import DynamicGrid from "./DynamicGrid";
import CloseIcon from '@mui/icons-material/Close';
import DynamicForm from "./DynamicForm";
import DeleteDialog from "../DeleteDialog";
import { useDynamicFormsStore } from "./DynamicStore";
import { useNotifyStore } from "../Notifyer";

const styles = {
  titleBox: {
    textAlign: "left",
    margin: "10px"
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: "20px"
  },
}

interface DynamicFormProps {
  handleMoreActions?: (data: any, element: HTMLElement) => void,
  disableEdition?: boolean,
  relatedTableID?: string,
  relatedModelID?: string
}

const DynamicModel: React.FC<DynamicFormProps> = ({ handleMoreActions, relatedTableID, relatedModelID, disableEdition }) => {

  const { tableMetadata, deleteModel, query, queryFilter } = useDynamicFormsStore();
  const { addMsg } = useNotifyStore();
  const [showForm, setShowForm] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const [selectedModel, setSelectedModel] = useState<Object>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    if (!showForm) {
      setSelectedModel(undefined);
      setUpdateMode(false);
      setReadOnlyMode(false);
    }
  }, [showForm])

  const handleDeleteConfirmation = (confirmation: boolean) => {
    if (confirmation) {
      const id = tableMetadata?.getKeyAttribute();
      deleteModel(Reflect.get(selectedModel as any, id as string), relatedTableID, relatedModelID)
        .then(() => {
          addMsg("success", "Registro excluído com sucesso.")
        })
        .catch(e => {
          if (e?.remote?.hasErrors) {
            const msg = e.remote.errors.join('\n');
            addMsg("error", `Não foi possível atualizar registro.\n${msg}`);
          }
        });

      query(queryFilter, relatedTableID, relatedModelID);
    }

    setShowDeleteDialog(false);
  }

  const handleOnSave = (model: object) => {
    setShowForm(false);
    query(queryFilter, relatedTableID, relatedModelID);
  }

  return (
    <>
      <Box sx={styles.titleBox}>
        <Typography variant="h6" color="primary" gutterBottom component="div">
          {tableMetadata?.label + " - (" + tableMetadata?.name + ")"}
        </Typography>
        <Typography variant="subtitle2" color="primary" gutterBottom component="div">
          {tableMetadata?.description}
        </Typography>
      </Box>
      {!disableEdition &&
        <Box sx={styles.searchBox}>
          <Button size="small" variant="contained" onClick={() => setShowForm(true)}>Novo</Button>
        </Box>
      }
      <DynamicGrid
        metadata={tableMetadata}
        relatedTableID={relatedTableID}
        relatedModelID={relatedModelID}
        disableActions={disableEdition}
        setShowForm={setShowForm}
        setUpdateMode={setUpdateMode}
        setReadOnlyMode={setReadOnlyMode}
        setSelectedModel={setSelectedModel}
        setShowDeleteDialog={setShowDeleteDialog}
        handleMoreActions={handleMoreActions}
      />
      <Drawer
        disableEnforceFocus
        anchor="right"
        open={showForm}
      >
        <Box>
          <IconButton aria-label="close" size="small" onClick={() => setShowForm(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ margin: "10px 20px", minWidth: "600px", maxWidth: "600px" }}>
          <DynamicForm
            metadata={tableMetadata}
            relatedTableID={relatedTableID}
            relatedModelID={relatedModelID}
            onSave={handleOnSave}
            forUpdate={updateMode}
            forReadOnly={readOnlyMode}
            model={selectedModel as object} />
        </Box>
      </Drawer>
      <DeleteDialog confirmation={handleDeleteConfirmation} showDialog={showDeleteDialog} />
    </>
  )
}

export default DynamicModel;
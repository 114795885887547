export class SensorRasterView {

  public name: string | undefined;
  public description: string | undefined;
  public sensorID: string | undefined;
  public queryString: string | undefined;
  public order: number | undefined;

  constructor() {

  }
}
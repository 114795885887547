import { Typography, styled } from "@mui/material";
import {
  AppMap, IdentifyTool, LayerDefinition, LayerStyling, LayerType,
  LegendBuilder, MVTLayer, MapLegend, OPTFeatureLayer, OPTFeatureLoadFunction, StyleBuilder
} from "@opt/mapping";
import React, { useEffect, useState } from "react";
import Map from 'ol/Map';
import config from "../../config";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import { useTranslation } from "react-i18next";
import { useExplorerStore } from "./ExplorerStore";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import IdentifyResults from "./IdentifyResults";
import GeoJSON from "ol/format/GeoJSON";
import RemoteInspection from "../remote-inspection/RemoteInspection";
import ManualInspectionArea from "./ManualInspectionArea";
import STACSearcher from "./STACSearcher";
import MapSearch from "../map-search/MapSearch";
import { FeatureLike } from "ol/Feature";
import { FormatDate, TableMetadata } from "@opt/core";
import TimeSlider from "./TimeSlider";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { addMonths, startOfMonth, endOfMonth } from 'date-fns';
import MapReport from "../map-report/MapReport";

type ExplorerMapProps = {
  datasetID: string
}

const Root = styled('div')({
  display: "flex",
  height: "100%",
  width: "100%"
});

const MapArea = styled('div')({
  flex: "5 1 0"
});

const MapDiv = styled('div')({
  flex: "1",
  width: "100%",
  height: "100%"
});

const DetailsArea = styled('div')({
  flex: "2 1 auto",
  padding: "5px",
  maxWidth: "420px",
  overflowY: "scroll",
  height: "calc(100vh - 90px)!important",
  maxHeight: "calc(100vh - 90px)!important"
});

const ExplorerMap: React.FC<ExplorerMapProps> = ({ datasetID }) => {

  const { t } = useTranslation();

  const [map, setMap] = useState<Map>();
  const [layers, setLayers] = useState<LayerDefinition[]>([]);
  const { tables, internalTables, getTables, getInternalTables, getDatasetExtent } = useExplorerStore();
  const [results, setResults] = useState<Feature<Geometry>[]>([]);
  const [allidentifiableLayers, setIdentifiableLayers] = useState<TableMetadata[]>([]);
  const [inspectionsLayer, setInspectionsLayer] = useState<VectorLayer<VectorSource>>();

  const initialRange: [number, number] = [8, 12];

  useEffect(() => {
    setIdentifiableLayers(tables.concat(internalTables));
  }, [tables, internalTables])

  useEffect(() => {

    if (!map || !datasetID) return;

    (async () => {

      const aoiStyle = (feature: FeatureLike | undefined, resolution: number): Style => {

        const table = new LayerStyling();
        table.textEnabled = true;
        table.textExpression = "${parcel_id}";
        table.textMaxResolution = 8;
        table.textFontSize = 14;

        const style: Style = new Style({
          fill: new Fill({
            color: 'rgba(255, 0, 255, 0)'
          }),
          stroke: new Stroke({
            color: 'rgba(204, 0, 204, 1)',
            width: 2
          }),
          text: StyleBuilder.createTextStyle(feature, resolution, table)
        });

        return style;
      }

      const pendingStyle: Style = new Style({
        fill: new Fill({
          color: 'rgba(255,59,1,0.5)'
        }),
        stroke: new Stroke({
          color: 'rgba(255,59,1,1)',
          width: 2
        })
      });

      const verifyStyle: Style = new Style({
        fill: new Fill({
          color: 'rgba(126,186,235,0.5)'
        }),
        stroke: new Stroke({
          color: 'rgba(126,186,235,1)',
          width: 2
        })
      });

      const monitorStyle: Style = new Style({
        fill: new Fill({
          color: 'rgba(254,209,65,0.5)'
        }),
        stroke: new Stroke({
          color: 'rgba(254,209,65,1)',
          width: 2
        })
      });

      const remoteStyle: Style = new Style({
        fill: new Fill({
          color: 'rgba(209,211,170,0.5)'
        }),
        stroke: new Stroke({
          color: 'rgba(209,211,170,1)',
          width: 2
        })
      });

      const fieldStyle: Style = new Style({
        fill: new Fill({
          color: 'rgba(67,84,32,0.5)'
        }),
        stroke: new Stroke({
          color: 'rgba(67,84,32,1)',
          width: 2
        })
      });

      const noproblemStyle: Style = new Style({
        fill: new Fill({
          color: 'rgba(27,35,41,0.5)'
        }),
        stroke: new Stroke({
          color: 'rgba(27,35,41,1)',
          width: 2
        })
      });

      const styleFunc = (feature: FeatureLike | undefined, resolution: number) => {
        const status = feature?.get("status");

        if (status === "PENDING") {
          return pendingStyle;
        } else if (status === "VERIFY") {
          return verifyStyle;
        } else if (status === "MONITOR") {
          return monitorStyle;
        } else if (status === "CONFIRMED_REMOTE") {
          return remoteStyle;
        } else if (status === "CONFIRMED_FIELD") {
          return fieldStyle;
        } else {
          return noproblemStyle;
        }
      }

      const legendEntries = [
        {
          label: t('components.explorer.inspectionTypes.status.PENDING'),
          fill: "rgba(255,59,1,0.5)",
          stroke: "rgba(255,59,1,1)"
        },
        {
          label: t('components.explorer.inspectionTypes.status.VERIFY'),
          fill: "rgba(126,186,235,0.5)",
          stroke: "rgba(126,186,235,1)"
        },
        {
          label: t('components.explorer.inspectionTypes.status.MONITOR'),
          fill: "rgba(254,209,65,0.5)",
          stroke: "rgba(254,209,65,1)"
        },
        {
          label: t('components.explorer.inspectionTypes.status.CONFIRMED_REMOTE'),
          fill: "rgba(209,211,170,0.5)",
          stroke: "rgba(209,211,170,1)"
        },
        {
          label: t('components.explorer.inspectionTypes.status.CONFIRMED_FIELD'),
          fill: "rgba(67,84,32,0.5)",
          stroke: "rgba(67,84,32,1)"
        },
        {
          label: t('components.explorer.inspectionTypes.status.NOT_PROBLEM'),
          fill: "rgba(27,35,41,0.5)",
          stroke: "rgba(27,35,41,1)"
        }
      ]

      const styleDef = new LayerStyling();
      styleDef.styleType = "FUNCTION";
      styleDef.legendOverride = JSON.stringify(legendEntries);
      styleDef.maxVisibleZoom = 22;
      styleDef.minVisibleZoom = 10;

      const aoiDefinition = new LayerDefinition("area_of_interest", LayerType.OPT,
        `${config.gisServices.url}/optfeature/datasets/${datasetID}`,
        t('components.explorer.layers.aoiLayer'), 7, true, true, true, undefined, aoiStyle);

      const start = startOfMonth(addMonths(new Date(), initialRange[0] - 12));
      const end = endOfMonth(addMonths(new Date(), initialRange[1] - 12));

      const inspectionDefinition = new LayerDefinition("inspections", LayerType.OPT,
        `${config.gisServices.url}/optfeature/datasets/${datasetID}/inspections?sd=${FormatDate(start)}&ed=${FormatDate(end)}`,
        t('components.explorer.layers.inspections'), 8, true, true, true, undefined, styleFunc);
      inspectionDefinition.styleDefinition = styleDef;

      const tables = await getTables(datasetID);
      await getInternalTables();

      const tablesDefinition = tables.map(table => {
        const url = `${config.gisServices.url}/mvt/table/${table.id}/{z}/{x}/{y}.pbf`;
        const style = StyleBuilder.FromTableMetadata(table);
        const definition = new LayerDefinition(table.id as string, LayerType.MVT, url, table.label, 4, false, true, true, undefined, style);
        definition.geometryType = table.geometryType;

        if (table.styleFile) {
          const legend = LegendBuilder.fromSLD(table);
          definition.legend = legend;
        }
        return definition;
      })

      setLayers([aoiDefinition, inspectionDefinition].concat(tablesDefinition));
    })().then(_ => {
      getDatasetExtent(datasetID)
        .then(result => {
          const geom = new GeoJSON().readGeometry(result);

          if (map?.getLoadingOrNotReady()) {
            map?.once("loadend", async () => {
              map?.getView().fit(geom.getExtent());
            })
          } else {
            map?.getView().fit(geom.getExtent());
          }
        });
    });

  }, [map, datasetID])

  const handleMapCreated = (map: Map) => {
    setMap(map);
  }

  const setIdentifyResults = (results: Feature<Geometry>[]) => {
    setResults(results)
  }

  const handleChangeSliderRange = (start: Date, end: Date) => {
    const url = `${config.gisServices.url}/optfeature/datasets/${datasetID}/inspections?sd=${FormatDate(start)}&ed=${FormatDate(end)}`;
    inspectionsLayer?.getSource()?.setLoader(OPTFeatureLoadFunction(url));
    inspectionsLayer?.getSource()?.refresh();
  }

  const handleInspectionCreated = () => {
    inspectionsLayer?.getSource()?.refresh();
  }

  return (
    <Root>
      <MapArea>
        <MapDiv>
          <AppMap mapHeight="100%" onMapCreated={(map: any) => handleMapCreated(map)}>
            {layers.map(layer => {
              if (layer.type === LayerType.MVT) {
                return <MVTLayer key={layer.key} definition={layer} />
              }
              else if (layer.type === LayerType.OPT) {
                return <OPTFeatureLayer key={layer.key} definition={layer} onLayerCreated={setInspectionsLayer} />
              }
            })}
            <MapLegend></MapLegend>
            <IdentifyTool map={map} setIdentifyResults={setIdentifyResults} targetLayers={layers.map(x => x.key ?? '')} />
            <IdentifyResults map={map} tablesMetadata={allidentifiableLayers} results={results} />
            <MapSearch map={map} datasetID={datasetID} zoomTo />
            <TimeSlider map={map} initialValue={initialRange} onChage={handleChangeSliderRange} />
          </AppMap>
        </MapDiv>
      </MapArea>
      <DetailsArea>
        <Typography
          color="primary"
          fontWeight={"bold"}
          variant="h5"
          sx={{ padding: "20px" }}>
          {t('components.explorer.title')}
        </Typography>
        <RemoteInspection map={map} zIndex={3} />
        <ManualInspectionArea map={map} datasetID={datasetID} onInspectionCreated={handleInspectionCreated} />
        <MapReport map={map} />
        <STACSearcher map={map} defaultExpanded />
      </DetailsArea>
    </Root>
  )
}

export default ExplorerMap;
import { SensorRasterView } from "./SensorRasterView";

export class Sensor {

  public id: string | undefined;
  public description: string | undefined;
  public rasterViews: SensorRasterView[];

  constructor() {
    this.rasterViews = [];
  }
}
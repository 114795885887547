import { create } from "zustand";
import { Dataset } from "../../models/Dataset";
import { DataFetchAPI, PostAPI, TableMetadata } from "@opt/core";
import config from "../../config";
import { Geometry } from "ol/geom";
import { startOfDay, endOfDay, subDays } from 'date-fns';
import { StacItem } from "../../models/StacItem";
import { Sensor } from "../../models/Sensor";

interface ExplorerState {
  stacItems: StacItem[],
  datasets: Dataset[],
  tables: TableMetadata[],
  internalTables: TableMetadata[],
  collections: Sensor[],
  filterRangeDate: [Date, Date],
  filterCollection: string | undefined,
  setFilterCollection: (filter: string) => void,
  setFilterRangeDate: (range: [Date, Date]) => void,
  setCollections: (collections: Sensor[]) => void,
  setResults: (results: StacItem[]) => void,
  getDatasets: () => Promise<Dataset[]>,
  getExtras: (aoiID: string) => Promise<any>,
  getTables: (datasetID: string) => Promise<TableMetadata[]>,
  getInternalTables: () => Promise<TableMetadata[]>,
  getDatasetExtent: (datasetID: string) => Promise<Geometry | undefined>,
  createInspection: (eventID: string, inspectionType: string, observations: string | undefined, featureCollection: any) => Promise<any>;
}

const getExtras = (aoiID: string) => {
  const { doFetch } = DataFetchAPI<Object>(Object, `${config.apiServices.url}/explorer/aoi/${aoiID}/extras`);
  return doFetch;
}
const { doFetchMany: getDatasets } = DataFetchAPI<Dataset>(Dataset, `${config.apiServices.url}/explorer/dataset`);

const getTables = (datasetID: string) => {
  const { doFetchMany } = DataFetchAPI<TableMetadata>(TableMetadata, `${config.apiServices.url}/explorer/dataset/${datasetID}/tables`);
  return doFetchMany;
}

const { doFetchMany: getInternalTables } = DataFetchAPI<TableMetadata>(TableMetadata, `${config.apiServices.url}/explorer/systables`);

const getDatasetExtent = (datasetID: string) => {
  const { doFetch } = DataFetchAPI<Geometry>(Geometry, `${config.apiServices.url}/explorer/dataset/${datasetID}/extent`);
  return doFetch;
}

const postNewInspection = (datasetID: string) => {
  const { doPost } = PostAPI(Object, `${config.apiServices.url}/explorer/${datasetID}/inspection`);
  return doPost;
}

export const useExplorerStore = create<ExplorerState>((set, get) => ({
  stacItems: [],
  datasets: [],
  tables: [],
  internalTables: [],
  collections: [],
  filterRangeDate: [startOfDay(subDays(new Date(), 30)), endOfDay(new Date())],
  filterCollection: undefined,

  setCollections: (collections: Sensor[]) => {
    set({ collections: collections });
  },

  setResults: (results: StacItem[]) => {
    set({ stacItems: results });
  },

  setFilterCollection: (filter: string) => {
    set({ filterCollection: filter });
  },

  setFilterRangeDate: (range: [Date, Date]) => {
    set({ filterRangeDate: range });
  },

  getDatasets: async () => {
    const query = await getDatasets() ?? [];
    set({ datasets: query });
    return query;
  },

  getExtras: async (aoiID: string) => {
    const query = await getExtras(aoiID)("");
    return query;
  },

  getTables: async (datasetID: string) => {
    const query = await getTables(datasetID)() ?? [];
    set({ tables: query });
    return query;
  },

  getInternalTables: async () => {
    const query = await getInternalTables() ?? [];
    set({ internalTables: query });
    return query;
  },

  getDatasetExtent: async (datasetID: string) => {
    const query = await getDatasetExtent(datasetID)('');
    return query;
  },

  createInspection: async (datasetID: string, inspectionType: string, observations: string | undefined, featureCollection: any) => {
    const body = {
      inspectionType: inspectionType,
      observations: observations,
      features: featureCollection
    }
    const query = await postNewInspection(datasetID)(body);

    return query;
  },
}))